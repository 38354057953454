import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">
            Socially Responsible Investing{' '}
          </h2>
        }
      />
      <ContentInner
        heading={'Socially Responsible Investing '}
        content={
          <p>
            Much has been written about Socially Responsible Investing (SRI)
            recently, and it can mean different things to different people.
            Generally speaking, SRI is any strategy that does not simply take
            into account investment performance, but also environmental, social,
            and corporate governance (ESG) factors as well.
            <br />
            <br />
            There are over 500 mutual funds, about 100 ETFs, and a number of
            separately managed accounts in our universe that focus on SRI. Among
            these, some focus on themes such as social and environmental impact.
            Some funds focus on sustainability such as the “green economy”,
            water infrastructure, carbon emissions, and energy efficiency. We
            can help you navigate through all of this and make sure your money
            is invested alongside your principles.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
